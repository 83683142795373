<template>
  <div style="height: inherit">

    <!-- Filters -->
    <!--    <users-list-filters-->
    <!--      :gender-filter.sync="genderFilter"-->
    <!--      :status-filter.sync="statusFilter"-->
    <!--      :gender-options="genders"-->
    <!--      :status-options="status"-->
    <!--      :view-type.sync="viewType"-->
    <!--    />-->

    <items-table />
  </div>
</template>

<script>

// eslint-disable-next-line import/extensions
import Ripple from 'vue-ripple-directive'
import ItemsTable from '@/views/iscritti/iscritti/iscritti-list/UsersTable.vue'

export default {
  name: 'UserEdit',
  components: {
    // ItemsThumbnails,
    ItemsTable,
    // NewItem,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
    .item-view-radio-group ::v-deep {
        .btn {
            display: flex;
            align-items: center;
        }
    }
</style>
